import revive_payload_client_4sVQNw7RlN from "C:/Users/HP/WebstormProjects/EMCI/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "C:/Users/HP/WebstormProjects/EMCI/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "C:/Users/HP/WebstormProjects/EMCI/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "C:/Users/HP/WebstormProjects/EMCI/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "C:/Users/HP/WebstormProjects/EMCI/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "C:/Users/HP/WebstormProjects/EMCI/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "C:/Users/HP/WebstormProjects/EMCI/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_eTVJQYlCmx from "C:/Users/HP/WebstormProjects/EMCI/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import plugin_OrkQhMqHci from "C:/Users/HP/WebstormProjects/EMCI/node_modules/@nuxt/image-edge/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "C:/Users/HP/WebstormProjects/EMCI/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import apollo_1ILFkzpYSr from "C:/Users/HP/WebstormProjects/EMCI/plugins/apollo.ts";
import aos_client_rDSYU13oOX from "C:/Users/HP/WebstormProjects/EMCI/plugins/aos.client.ts";
import bootstrap_client_Uyd73t1ylZ from "C:/Users/HP/WebstormProjects/EMCI/plugins/bootstrap.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_eTVJQYlCmx,
  plugin_OrkQhMqHci,
  chunk_reload_client_UciE0i6zes,
  apollo_1ILFkzpYSr,
  aos_client_rDSYU13oOX,
  bootstrap_client_Uyd73t1ylZ
]