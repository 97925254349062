import { default as _4041h2cFVMln6Meta } from "C:/Users/HP/WebstormProjects/EMCI/pages/404.vue?macro=true";
import { default as _91titleA_93_45_91idA_93dDzNBHb0J7Meta } from "C:/Users/HP/WebstormProjects/EMCI/pages/actualites/[titleA]-[idA].vue?macro=true";
import { default as indexuWGHBPAjaKMeta } from "C:/Users/HP/WebstormProjects/EMCI/pages/actualites/index.vue?macro=true";
import { default as indexq7luYfw3GkMeta } from "C:/Users/HP/WebstormProjects/EMCI/pages/bilan_competences/index.vue?macro=true";
import { default as indexLevSU5r5VrMeta } from "C:/Users/HP/WebstormProjects/EMCI/pages/bilan_competences/nos_centres/index.vue?macro=true";
import { default as index8j3YxlUtSmMeta } from "C:/Users/HP/WebstormProjects/EMCI/pages/conseil_rh/entretien_annuel_professionnel/index.vue?macro=true";
import { default as index3tiqmE0iIQMeta } from "C:/Users/HP/WebstormProjects/EMCI/pages/conseil_rh/gepp/index.vue?macro=true";
import { default as indexJub9WenvagMeta } from "C:/Users/HP/WebstormProjects/EMCI/pages/conseil_rh/marque_employeur/index.vue?macro=true";
import { default as indexpePMKfGZV6Meta } from "C:/Users/HP/WebstormProjects/EMCI/pages/conseil_rh/obligations_legales/index.vue?macro=true";
import { default as indexmXoN7i56jWMeta } from "C:/Users/HP/WebstormProjects/EMCI/pages/conseil_rh/pcrh/index.vue?macro=true";
import { default as index5D2vImc6GZMeta } from "C:/Users/HP/WebstormProjects/EMCI/pages/contactez-nous/index.vue?macro=true";
import { default as cookiesssF5AZU1gCxfMeta } from "C:/Users/HP/WebstormProjects/EMCI/pages/cookiesss.vue?macro=true";
import { default as indexw6ZTmm6CxiMeta } from "C:/Users/HP/WebstormProjects/EMCI/pages/equipe-bilan-competences/index.vue?macro=true";
import { default as indexGquf9rxNETMeta } from "C:/Users/HP/WebstormProjects/EMCI/pages/equipe/index.vue?macro=true";
import { default as indexAPRvdBm2MZMeta } from "C:/Users/HP/WebstormProjects/EMCI/pages/formulaire-reclamation/index.vue?macro=true";
import { default as indexK1UHWSnizEMeta } from "C:/Users/HP/WebstormProjects/EMCI/pages/fr/index.vue?macro=true";
import { default as indexTbz8D7Y6wyMeta } from "C:/Users/HP/WebstormProjects/EMCI/pages/index.vue?macro=true";
import { default as indexT84Xseo338Meta } from "C:/Users/HP/WebstormProjects/EMCI/pages/leadership-management/index.vue?macro=true";
import { default as indexKyPYKBHQI1Meta } from "C:/Users/HP/WebstormProjects/EMCI/pages/management/accompagnement_codir/index.vue?macro=true";
import { default as indexATYxeHS2fxMeta } from "C:/Users/HP/WebstormProjects/EMCI/pages/management/co_developpement/index.vue?macro=true";
import { default as indexi7azOxnJrbMeta } from "C:/Users/HP/WebstormProjects/EMCI/pages/management/coaching_professionnel/index.vue?macro=true";
import { default as indexRwZ7n8ajBOMeta } from "C:/Users/HP/WebstormProjects/EMCI/pages/management/conduite_du_changement/index.vue?macro=true";
import { default as index8PENdcIjlOMeta } from "C:/Users/HP/WebstormProjects/EMCI/pages/management/team_building/index.vue?macro=true";
import { default as indexIIThRSTTJgMeta } from "C:/Users/HP/WebstormProjects/EMCI/pages/mentions-legales/index.vue?macro=true";
import { default as indexPjFEY5DNvYMeta } from "C:/Users/HP/WebstormProjects/EMCI/pages/offre-emploi/index.vue?macro=true";
import { default as _91idC_93yBux1KhnfvMeta } from "C:/Users/HP/WebstormProjects/EMCI/pages/offre-emploi/offres/[idC].vue?macro=true";
import { default as indexcAJv2scToLMeta } from "C:/Users/HP/WebstormProjects/EMCI/pages/offre-emploi/offres/index.vue?macro=true";
import { default as indexKRkrHpilYcMeta } from "C:/Users/HP/WebstormProjects/EMCI/pages/organisme-formation/index.vue?macro=true";
import { default as index2Exd7uDjOJMeta } from "C:/Users/HP/WebstormProjects/EMCI/pages/preparez-votre-formation/index.vue?macro=true";
import { default as presentation9bO9FWPvqrMeta } from "C:/Users/HP/WebstormProjects/EMCI/pages/presentation.vue?macro=true";
import { default as indexN56jJpHfdSMeta } from "C:/Users/HP/WebstormProjects/EMCI/pages/qualite-vie-condition-travail/audit-social/index.vue?macro=true";
import { default as indexpXRjSRH9xZMeta } from "C:/Users/HP/WebstormProjects/EMCI/pages/qualite-vie-condition-travail/gap/index.vue?macro=true";
import { default as indexhZNBXAs2TJMeta } from "C:/Users/HP/WebstormProjects/EMCI/pages/qualite-vie-condition-travail/mediation/index.vue?macro=true";
import { default as indexhVONzrhoCAMeta } from "C:/Users/HP/WebstormProjects/EMCI/pages/qualite-vie-condition-travail/prevention-rps/index.vue?macro=true";
import { default as indexLS1dv9hfouMeta } from "C:/Users/HP/WebstormProjects/EMCI/pages/qualite-vie-condition-travail/supervision/index.vue?macro=true";
import { default as indexAZsu2fSLpbMeta } from "C:/Users/HP/WebstormProjects/EMCI/pages/recrutement/approche-directe/index.vue?macro=true";
import { default as indexo5iTtchhsEMeta } from "C:/Users/HP/WebstormProjects/EMCI/pages/recrutement/assessment/index.vue?macro=true";
import { default as indexrFkvF26cj2Meta } from "C:/Users/HP/WebstormProjects/EMCI/pages/recrutement/evaluation-candidat/index.vue?macro=true";
import { default as indexDA2wuhkfBRMeta } from "C:/Users/HP/WebstormProjects/EMCI/pages/recrutement/recrutement/index.vue?macro=true";
import { default as indexP0061bf4pqMeta } from "C:/Users/HP/WebstormProjects/EMCI/pages/ressources-humaines-rse/index.vue?macro=true";
import { default as indexVoIxqRdEnIMeta } from "C:/Users/HP/WebstormProjects/EMCI/pages/sessions/index.vue?macro=true";
import { default as indexeti42bNhsyMeta } from "C:/Users/HP/WebstormProjects/EMCI/pages/soft-skills/index.vue?macro=true";
import { default as indexazcW1N1O51Meta } from "C:/Users/HP/WebstormProjects/EMCI/pages/transition-professionnelle/activ-projet/index.vue?macro=true";
import { default as indextV2jlwOmrqMeta } from "C:/Users/HP/WebstormProjects/EMCI/pages/transition-professionnelle/bilan-retraite/index.vue?macro=true";
import { default as indexGbcZSLCPwFMeta } from "C:/Users/HP/WebstormProjects/EMCI/pages/transition-professionnelle/index.vue?macro=true";
import { default as indexOeZM5xxIH1Meta } from "C:/Users/HP/WebstormProjects/EMCI/pages/transition-professionnelle/outplacement/index.vue?macro=true";
export default [
  {
    name: _4041h2cFVMln6Meta?.name ?? "404",
    path: _4041h2cFVMln6Meta?.path ?? "/404",
    meta: _4041h2cFVMln6Meta || {},
    alias: _4041h2cFVMln6Meta?.alias || [],
    redirect: _4041h2cFVMln6Meta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/EMCI/pages/404.vue").then(m => m.default || m)
  },
  {
    name: _91titleA_93_45_91idA_93dDzNBHb0J7Meta?.name ?? "actualites-titleA-idA",
    path: _91titleA_93_45_91idA_93dDzNBHb0J7Meta?.path ?? "/actualites/:titleA()-:idA()",
    meta: _91titleA_93_45_91idA_93dDzNBHb0J7Meta || {},
    alias: _91titleA_93_45_91idA_93dDzNBHb0J7Meta?.alias || [],
    redirect: _91titleA_93_45_91idA_93dDzNBHb0J7Meta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/EMCI/pages/actualites/[titleA]-[idA].vue").then(m => m.default || m)
  },
  {
    name: indexuWGHBPAjaKMeta?.name ?? "actualites",
    path: indexuWGHBPAjaKMeta?.path ?? "/actualites",
    meta: indexuWGHBPAjaKMeta || {},
    alias: indexuWGHBPAjaKMeta?.alias || [],
    redirect: indexuWGHBPAjaKMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/EMCI/pages/actualites/index.vue").then(m => m.default || m)
  },
  {
    name: indexq7luYfw3GkMeta?.name ?? "bilan_competences",
    path: indexq7luYfw3GkMeta?.path ?? "/bilan_competences",
    meta: indexq7luYfw3GkMeta || {},
    alias: indexq7luYfw3GkMeta?.alias || [],
    redirect: indexq7luYfw3GkMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/EMCI/pages/bilan_competences/index.vue").then(m => m.default || m)
  },
  {
    name: indexLevSU5r5VrMeta?.name ?? "bilan_competences-nos_centres",
    path: indexLevSU5r5VrMeta?.path ?? "/bilan_competences/nos_centres",
    meta: indexLevSU5r5VrMeta || {},
    alias: indexLevSU5r5VrMeta?.alias || [],
    redirect: indexLevSU5r5VrMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/EMCI/pages/bilan_competences/nos_centres/index.vue").then(m => m.default || m)
  },
  {
    name: index8j3YxlUtSmMeta?.name ?? "conseil_rh-entretien_annuel_professionnel",
    path: index8j3YxlUtSmMeta?.path ?? "/conseil_rh/entretien_annuel_professionnel",
    meta: index8j3YxlUtSmMeta || {},
    alias: index8j3YxlUtSmMeta?.alias || [],
    redirect: index8j3YxlUtSmMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/EMCI/pages/conseil_rh/entretien_annuel_professionnel/index.vue").then(m => m.default || m)
  },
  {
    name: index3tiqmE0iIQMeta?.name ?? "conseil_rh-gepp",
    path: index3tiqmE0iIQMeta?.path ?? "/conseil_rh/gepp",
    meta: index3tiqmE0iIQMeta || {},
    alias: index3tiqmE0iIQMeta?.alias || [],
    redirect: index3tiqmE0iIQMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/EMCI/pages/conseil_rh/gepp/index.vue").then(m => m.default || m)
  },
  {
    name: indexJub9WenvagMeta?.name ?? "conseil_rh-marque_employeur",
    path: indexJub9WenvagMeta?.path ?? "/conseil_rh/marque_employeur",
    meta: indexJub9WenvagMeta || {},
    alias: indexJub9WenvagMeta?.alias || [],
    redirect: indexJub9WenvagMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/EMCI/pages/conseil_rh/marque_employeur/index.vue").then(m => m.default || m)
  },
  {
    name: indexpePMKfGZV6Meta?.name ?? "conseil_rh-obligations_legales",
    path: indexpePMKfGZV6Meta?.path ?? "/conseil_rh/obligations_legales",
    meta: indexpePMKfGZV6Meta || {},
    alias: indexpePMKfGZV6Meta?.alias || [],
    redirect: indexpePMKfGZV6Meta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/EMCI/pages/conseil_rh/obligations_legales/index.vue").then(m => m.default || m)
  },
  {
    name: indexmXoN7i56jWMeta?.name ?? "conseil_rh-pcrh",
    path: indexmXoN7i56jWMeta?.path ?? "/conseil_rh/pcrh",
    meta: indexmXoN7i56jWMeta || {},
    alias: indexmXoN7i56jWMeta?.alias || [],
    redirect: indexmXoN7i56jWMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/EMCI/pages/conseil_rh/pcrh/index.vue").then(m => m.default || m)
  },
  {
    name: index5D2vImc6GZMeta?.name ?? "contactez-nous",
    path: index5D2vImc6GZMeta?.path ?? "/contactez-nous",
    meta: index5D2vImc6GZMeta || {},
    alias: index5D2vImc6GZMeta?.alias || [],
    redirect: index5D2vImc6GZMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/EMCI/pages/contactez-nous/index.vue").then(m => m.default || m)
  },
  {
    name: cookiesssF5AZU1gCxfMeta?.name ?? "cookiesss",
    path: cookiesssF5AZU1gCxfMeta?.path ?? "/cookiesss",
    meta: cookiesssF5AZU1gCxfMeta || {},
    alias: cookiesssF5AZU1gCxfMeta?.alias || [],
    redirect: cookiesssF5AZU1gCxfMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/EMCI/pages/cookiesss.vue").then(m => m.default || m)
  },
  {
    name: indexw6ZTmm6CxiMeta?.name ?? "equipe-bilan-competences",
    path: indexw6ZTmm6CxiMeta?.path ?? "/equipe-bilan-competences",
    meta: indexw6ZTmm6CxiMeta || {},
    alias: indexw6ZTmm6CxiMeta?.alias || [],
    redirect: indexw6ZTmm6CxiMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/EMCI/pages/equipe-bilan-competences/index.vue").then(m => m.default || m)
  },
  {
    name: indexGquf9rxNETMeta?.name ?? "equipe",
    path: indexGquf9rxNETMeta?.path ?? "/equipe",
    meta: indexGquf9rxNETMeta || {},
    alias: indexGquf9rxNETMeta?.alias || [],
    redirect: indexGquf9rxNETMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/EMCI/pages/equipe/index.vue").then(m => m.default || m)
  },
  {
    name: indexAPRvdBm2MZMeta?.name ?? "formulaire-reclamation",
    path: indexAPRvdBm2MZMeta?.path ?? "/formulaire-reclamation",
    meta: indexAPRvdBm2MZMeta || {},
    alias: indexAPRvdBm2MZMeta?.alias || [],
    redirect: indexAPRvdBm2MZMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/EMCI/pages/formulaire-reclamation/index.vue").then(m => m.default || m)
  },
  {
    name: indexK1UHWSnizEMeta?.name ?? "fr",
    path: indexK1UHWSnizEMeta?.path ?? "/fr",
    meta: indexK1UHWSnizEMeta || {},
    alias: indexK1UHWSnizEMeta?.alias || [],
    redirect: indexK1UHWSnizEMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/EMCI/pages/fr/index.vue").then(m => m.default || m)
  },
  {
    name: indexTbz8D7Y6wyMeta?.name ?? "index",
    path: indexTbz8D7Y6wyMeta?.path ?? "/",
    meta: indexTbz8D7Y6wyMeta || {},
    alias: indexTbz8D7Y6wyMeta?.alias || [],
    redirect: indexTbz8D7Y6wyMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/EMCI/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexT84Xseo338Meta?.name ?? "leadership-management",
    path: indexT84Xseo338Meta?.path ?? "/leadership-management",
    meta: indexT84Xseo338Meta || {},
    alias: indexT84Xseo338Meta?.alias || [],
    redirect: indexT84Xseo338Meta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/EMCI/pages/leadership-management/index.vue").then(m => m.default || m)
  },
  {
    name: indexKyPYKBHQI1Meta?.name ?? "management-accompagnement_codir",
    path: indexKyPYKBHQI1Meta?.path ?? "/management/accompagnement_codir",
    meta: indexKyPYKBHQI1Meta || {},
    alias: indexKyPYKBHQI1Meta?.alias || [],
    redirect: indexKyPYKBHQI1Meta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/EMCI/pages/management/accompagnement_codir/index.vue").then(m => m.default || m)
  },
  {
    name: indexATYxeHS2fxMeta?.name ?? "management-co_developpement",
    path: indexATYxeHS2fxMeta?.path ?? "/management/co_developpement",
    meta: indexATYxeHS2fxMeta || {},
    alias: indexATYxeHS2fxMeta?.alias || [],
    redirect: indexATYxeHS2fxMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/EMCI/pages/management/co_developpement/index.vue").then(m => m.default || m)
  },
  {
    name: indexi7azOxnJrbMeta?.name ?? "management-coaching_professionnel",
    path: indexi7azOxnJrbMeta?.path ?? "/management/coaching_professionnel",
    meta: indexi7azOxnJrbMeta || {},
    alias: indexi7azOxnJrbMeta?.alias || [],
    redirect: indexi7azOxnJrbMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/EMCI/pages/management/coaching_professionnel/index.vue").then(m => m.default || m)
  },
  {
    name: indexRwZ7n8ajBOMeta?.name ?? "management-conduite_du_changement",
    path: indexRwZ7n8ajBOMeta?.path ?? "/management/conduite_du_changement",
    meta: indexRwZ7n8ajBOMeta || {},
    alias: indexRwZ7n8ajBOMeta?.alias || [],
    redirect: indexRwZ7n8ajBOMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/EMCI/pages/management/conduite_du_changement/index.vue").then(m => m.default || m)
  },
  {
    name: index8PENdcIjlOMeta?.name ?? "management-team_building",
    path: index8PENdcIjlOMeta?.path ?? "/management/team_building",
    meta: index8PENdcIjlOMeta || {},
    alias: index8PENdcIjlOMeta?.alias || [],
    redirect: index8PENdcIjlOMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/EMCI/pages/management/team_building/index.vue").then(m => m.default || m)
  },
  {
    name: indexIIThRSTTJgMeta?.name ?? "mentions-legales",
    path: indexIIThRSTTJgMeta?.path ?? "/mentions-legales",
    meta: indexIIThRSTTJgMeta || {},
    alias: indexIIThRSTTJgMeta?.alias || [],
    redirect: indexIIThRSTTJgMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/EMCI/pages/mentions-legales/index.vue").then(m => m.default || m)
  },
  {
    name: indexPjFEY5DNvYMeta?.name ?? "offre-emploi",
    path: indexPjFEY5DNvYMeta?.path ?? "/offre-emploi",
    meta: indexPjFEY5DNvYMeta || {},
    alias: indexPjFEY5DNvYMeta?.alias || [],
    redirect: indexPjFEY5DNvYMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/EMCI/pages/offre-emploi/index.vue").then(m => m.default || m)
  },
  {
    name: _91idC_93yBux1KhnfvMeta?.name ?? "offre-emploi-offres-idC",
    path: _91idC_93yBux1KhnfvMeta?.path ?? "/offre-emploi/offres/:idC()",
    meta: _91idC_93yBux1KhnfvMeta || {},
    alias: _91idC_93yBux1KhnfvMeta?.alias || [],
    redirect: _91idC_93yBux1KhnfvMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/EMCI/pages/offre-emploi/offres/[idC].vue").then(m => m.default || m)
  },
  {
    name: indexcAJv2scToLMeta?.name ?? "offre-emploi-offres",
    path: indexcAJv2scToLMeta?.path ?? "/offre-emploi/offres",
    meta: indexcAJv2scToLMeta || {},
    alias: indexcAJv2scToLMeta?.alias || [],
    redirect: indexcAJv2scToLMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/EMCI/pages/offre-emploi/offres/index.vue").then(m => m.default || m)
  },
  {
    name: indexKRkrHpilYcMeta?.name ?? "organisme-formation",
    path: indexKRkrHpilYcMeta?.path ?? "/organisme-formation",
    meta: indexKRkrHpilYcMeta || {},
    alias: indexKRkrHpilYcMeta?.alias || [],
    redirect: indexKRkrHpilYcMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/EMCI/pages/organisme-formation/index.vue").then(m => m.default || m)
  },
  {
    name: index2Exd7uDjOJMeta?.name ?? "preparez-votre-formation",
    path: index2Exd7uDjOJMeta?.path ?? "/preparez-votre-formation",
    meta: index2Exd7uDjOJMeta || {},
    alias: index2Exd7uDjOJMeta?.alias || [],
    redirect: index2Exd7uDjOJMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/EMCI/pages/preparez-votre-formation/index.vue").then(m => m.default || m)
  },
  {
    name: presentation9bO9FWPvqrMeta?.name ?? "presentation",
    path: presentation9bO9FWPvqrMeta?.path ?? "/presentation",
    meta: presentation9bO9FWPvqrMeta || {},
    alias: presentation9bO9FWPvqrMeta?.alias || [],
    redirect: presentation9bO9FWPvqrMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/EMCI/pages/presentation.vue").then(m => m.default || m)
  },
  {
    name: indexN56jJpHfdSMeta?.name ?? "qualite-vie-condition-travail-audit-social",
    path: indexN56jJpHfdSMeta?.path ?? "/qualite-vie-condition-travail/audit-social",
    meta: indexN56jJpHfdSMeta || {},
    alias: indexN56jJpHfdSMeta?.alias || [],
    redirect: indexN56jJpHfdSMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/EMCI/pages/qualite-vie-condition-travail/audit-social/index.vue").then(m => m.default || m)
  },
  {
    name: indexpXRjSRH9xZMeta?.name ?? "qualite-vie-condition-travail-gap",
    path: indexpXRjSRH9xZMeta?.path ?? "/qualite-vie-condition-travail/gap",
    meta: indexpXRjSRH9xZMeta || {},
    alias: indexpXRjSRH9xZMeta?.alias || [],
    redirect: indexpXRjSRH9xZMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/EMCI/pages/qualite-vie-condition-travail/gap/index.vue").then(m => m.default || m)
  },
  {
    name: indexhZNBXAs2TJMeta?.name ?? "qualite-vie-condition-travail-mediation",
    path: indexhZNBXAs2TJMeta?.path ?? "/qualite-vie-condition-travail/mediation",
    meta: indexhZNBXAs2TJMeta || {},
    alias: indexhZNBXAs2TJMeta?.alias || [],
    redirect: indexhZNBXAs2TJMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/EMCI/pages/qualite-vie-condition-travail/mediation/index.vue").then(m => m.default || m)
  },
  {
    name: indexhVONzrhoCAMeta?.name ?? "qualite-vie-condition-travail-prevention-rps",
    path: indexhVONzrhoCAMeta?.path ?? "/qualite-vie-condition-travail/prevention-rps",
    meta: indexhVONzrhoCAMeta || {},
    alias: indexhVONzrhoCAMeta?.alias || [],
    redirect: indexhVONzrhoCAMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/EMCI/pages/qualite-vie-condition-travail/prevention-rps/index.vue").then(m => m.default || m)
  },
  {
    name: indexLS1dv9hfouMeta?.name ?? "qualite-vie-condition-travail-supervision",
    path: indexLS1dv9hfouMeta?.path ?? "/qualite-vie-condition-travail/supervision",
    meta: indexLS1dv9hfouMeta || {},
    alias: indexLS1dv9hfouMeta?.alias || [],
    redirect: indexLS1dv9hfouMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/EMCI/pages/qualite-vie-condition-travail/supervision/index.vue").then(m => m.default || m)
  },
  {
    name: indexAZsu2fSLpbMeta?.name ?? "recrutement-approche-directe",
    path: indexAZsu2fSLpbMeta?.path ?? "/recrutement/approche-directe",
    meta: indexAZsu2fSLpbMeta || {},
    alias: indexAZsu2fSLpbMeta?.alias || [],
    redirect: indexAZsu2fSLpbMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/EMCI/pages/recrutement/approche-directe/index.vue").then(m => m.default || m)
  },
  {
    name: indexo5iTtchhsEMeta?.name ?? "recrutement-assessment",
    path: indexo5iTtchhsEMeta?.path ?? "/recrutement/assessment",
    meta: indexo5iTtchhsEMeta || {},
    alias: indexo5iTtchhsEMeta?.alias || [],
    redirect: indexo5iTtchhsEMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/EMCI/pages/recrutement/assessment/index.vue").then(m => m.default || m)
  },
  {
    name: indexrFkvF26cj2Meta?.name ?? "recrutement-evaluation-candidat",
    path: indexrFkvF26cj2Meta?.path ?? "/recrutement/evaluation-candidat",
    meta: indexrFkvF26cj2Meta || {},
    alias: indexrFkvF26cj2Meta?.alias || [],
    redirect: indexrFkvF26cj2Meta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/EMCI/pages/recrutement/evaluation-candidat/index.vue").then(m => m.default || m)
  },
  {
    name: indexDA2wuhkfBRMeta?.name ?? "recrutement-recrutement",
    path: indexDA2wuhkfBRMeta?.path ?? "/recrutement/recrutement",
    meta: indexDA2wuhkfBRMeta || {},
    alias: indexDA2wuhkfBRMeta?.alias || [],
    redirect: indexDA2wuhkfBRMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/EMCI/pages/recrutement/recrutement/index.vue").then(m => m.default || m)
  },
  {
    name: indexP0061bf4pqMeta?.name ?? "ressources-humaines-rse",
    path: indexP0061bf4pqMeta?.path ?? "/ressources-humaines-rse",
    meta: indexP0061bf4pqMeta || {},
    alias: indexP0061bf4pqMeta?.alias || [],
    redirect: indexP0061bf4pqMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/EMCI/pages/ressources-humaines-rse/index.vue").then(m => m.default || m)
  },
  {
    name: indexVoIxqRdEnIMeta?.name ?? "sessions",
    path: indexVoIxqRdEnIMeta?.path ?? "/sessions",
    meta: indexVoIxqRdEnIMeta || {},
    alias: indexVoIxqRdEnIMeta?.alias || [],
    redirect: indexVoIxqRdEnIMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/EMCI/pages/sessions/index.vue").then(m => m.default || m)
  },
  {
    name: indexeti42bNhsyMeta?.name ?? "soft-skills",
    path: indexeti42bNhsyMeta?.path ?? "/soft-skills",
    meta: indexeti42bNhsyMeta || {},
    alias: indexeti42bNhsyMeta?.alias || [],
    redirect: indexeti42bNhsyMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/EMCI/pages/soft-skills/index.vue").then(m => m.default || m)
  },
  {
    name: indexazcW1N1O51Meta?.name ?? "transition-professionnelle-activ-projet",
    path: indexazcW1N1O51Meta?.path ?? "/transition-professionnelle/activ-projet",
    meta: indexazcW1N1O51Meta || {},
    alias: indexazcW1N1O51Meta?.alias || [],
    redirect: indexazcW1N1O51Meta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/EMCI/pages/transition-professionnelle/activ-projet/index.vue").then(m => m.default || m)
  },
  {
    name: indextV2jlwOmrqMeta?.name ?? "transition-professionnelle-bilan-retraite",
    path: indextV2jlwOmrqMeta?.path ?? "/transition-professionnelle/bilan-retraite",
    meta: indextV2jlwOmrqMeta || {},
    alias: indextV2jlwOmrqMeta?.alias || [],
    redirect: indextV2jlwOmrqMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/EMCI/pages/transition-professionnelle/bilan-retraite/index.vue").then(m => m.default || m)
  },
  {
    name: indexGbcZSLCPwFMeta?.name ?? "transition-professionnelle",
    path: indexGbcZSLCPwFMeta?.path ?? "/transition-professionnelle",
    meta: indexGbcZSLCPwFMeta || {},
    alias: indexGbcZSLCPwFMeta?.alias || [],
    redirect: indexGbcZSLCPwFMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/EMCI/pages/transition-professionnelle/index.vue").then(m => m.default || m)
  },
  {
    name: indexOeZM5xxIH1Meta?.name ?? "transition-professionnelle-outplacement",
    path: indexOeZM5xxIH1Meta?.path ?? "/transition-professionnelle/outplacement",
    meta: indexOeZM5xxIH1Meta || {},
    alias: indexOeZM5xxIH1Meta?.alias || [],
    redirect: indexOeZM5xxIH1Meta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/EMCI/pages/transition-professionnelle/outplacement/index.vue").then(m => m.default || m)
  }
]