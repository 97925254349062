export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"robots","content":"index, follow"},{"hid":"twitter:image","property":"twitter:image","content":"/images/Logos/logoRsx.jpg"},{"hid":"twitter:image:src","property":"twitter:image:src","content":"/images/Logos/logoRsx.jpg"},{"hid":"og:image","property":"og:image","content":"/images/Logos/logoRsx.jpg"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon_io/favicon.ico"},{"rel":"stylesheet","href":"https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.css"},{"rel":"stylesheet","href":"https://fonts.googleapis.com/css?family=Outfit"},{"rel":"stylesheet","href":"https://cdn.jsdelivr.net/npm/magnific-popup@1.1.0/dist/magnific-popup.min.css"},{"rel":"stylesheet","href":"https://unpkg.com/tippy.js@6/animations/scale.css"}],"style":[],"script":[{"src":"https://code.jquery.com/jquery-3.7.0.js","body":true},{"type":"text/javascript","src":"https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.min.js","body":true},{"src":"https://cdn.jsdelivr.net/npm/magnific-popup@1.1.0/dist/jquery.magnific-popup.min.js","body":true},{"src":"https://unpkg.com/@popperjs/core@2","body":true},{"src":"https://unpkg.com/tippy.js@6","body":true},{"src":"https://www.google.com/recaptcha/api.js","async":true,"defer":true},{"src":"https://kit.fontawesome.com/f5e7510031.js","crossorigin":"anonymous"},{"src":"https://www.googletagmanager.com/gtag/js?id=G-GTHHB06JTH","async":true}],"noscript":[],"charset":"utf-8","viewport":"width=device-width, initial-scale=1"}

export const appPageTransition = {"name":"page","mode":"out-in"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false